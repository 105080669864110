import { Farmacia } from '@/models/farmacia';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { farmaciaState } from './types';
import {
  FETCH_FARMACIAS,
  RECEIVE_FARMACIAS,
  GET_FARMACIAS,
  SET_FARMACIA,
  UPDATE_FARMACIA,
  DELETE_FARMACIA,
} from './constants';
import getFarmacias from '@/api/farmacia/getFarmacias';

interface Context {
  commit: Commit;
}

const farmaciaModule: Module<farmaciaState, State> = {
  state: {
    farmacias: [],
  },
  mutations: {
    [RECEIVE_FARMACIAS](store: farmaciaState, farmacias: Farmacia[]) {
      store.farmacias = farmacias;
    },
    [SET_FARMACIA](store: farmaciaState, farmacia: Farmacia) {
      store.farmacias.unshift(farmacia);
    },
    [UPDATE_FARMACIA](store: farmaciaState, farmacia: Farmacia) {
      const index = store.farmacias.findIndex((pro) => pro.id == farmacia.id);
      store.farmacias.splice(index, 1, farmacia);
    },
    [DELETE_FARMACIA](store: farmaciaState, id: number | string) {
      const index = store.farmacias.findIndex((pro) => pro.id == id);
      store.farmacias.splice(index, 1);
    },
  },
  actions: {
    [FETCH_FARMACIAS]: async (context: Context) => {
      const farmacias = await getFarmacias();
      // console.log(farmacias);

      context.commit(RECEIVE_FARMACIAS, farmacias);
    },
  },
  getters: {
    [GET_FARMACIAS](state: farmaciaState) {
      return state.farmacias;
    },
  },
};

export default farmaciaModule;
