import { Messages } from './messages';

export class ClientMessages {
  cli_id!: number;
  name!: string;
  img!: string;
  time!: string;
  unread!: number;
  messages!: Messages[];
}
