import api from '..';

const getChats = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<any>(`${baseUrl}/chat/index`);
  // console.log('product', response.data);

  return response.data.clients;
};

export default getChats;
