import { Category } from '@/models/category';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { categoryState } from './types';
import {
  FETCH_CATEGORIES,
  RECEIVE_CATEGORIES,
  GET_CATEGORIES,
  SET_CATEGORY,
} from './constants';
import getCategories from '@/api/category/getCategories';

interface Context {
  commit: Commit;
}

const categoryModule: Module<categoryState, State> = {
  state: {
    categories: [],
  },
  mutations: {
    [RECEIVE_CATEGORIES](store: categoryState, categories: Category[]) {
      store.categories = categories;
    },
    [SET_CATEGORY](store: categoryState, cat: Category) {
      store.categories.push(cat);
    },
  },
  actions: {
    [FETCH_CATEGORIES]: async (context: Context) => {
      const categories = await getCategories();
      // console.log(categories);

      context.commit(RECEIVE_CATEGORIES, categories);
    },
  },
  getters: {
    [GET_CATEGORIES](state: categoryState) {
      return state.categories;
    },
  },
};

export default categoryModule;
