import { Province } from '@/models/province';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { provinceState } from './types';
import { FETCH_PROVINCES, RECEIVE_PROVINCES, GET_PROVINCES } from './constants';
import getProvinces from '@/api/province/getProvinces';

interface Context {
  commit: Commit;
}

const provinceModule: Module<provinceState, State> = {
  state: {
    provinces: [],
  },
  mutations: {
    [RECEIVE_PROVINCES](store: provinceState, provinces: Province[]) {
      store.provinces = provinces;
    },
  },
  actions: {
    [FETCH_PROVINCES]: async (context: Context) => {
      const provinces = await getProvinces();
      // console.log(provinces);

      context.commit(RECEIVE_PROVINCES, provinces);
    },
  },
  getters: {
    [GET_PROVINCES](state: provinceState) {
      return state.provinces;
    },
  },
};

export default provinceModule;
