import api from '@/api';
import { Payment } from '@/models/payment';

const getPayments = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Payment>(`${baseUrl}/payments`);
  // console.log('product', response.data);

  return response.data;
};

export default getPayments;
