import { Collaborator } from '@/models/collaborator';
import { Commit, Module } from 'vuex';
import { State } from '..';
import {
  FETCH_COLLABORATORS,
  RECEIVE_COLLABORATORS,
  GET_COLLABORATORS,
  SET_COLLABORATOR,
  DELETE_COLLABORATOR,
  UPDATE_COLLABORATOR,
} from './constants';
import getCollaborators from '@/api/collaborator/getCollaborators';
import { collaboratorState } from './types';

interface Context {
  commit: Commit;
}

const collaboratorModule: Module<collaboratorState, State> = {
  state: {
    collaborators: [],
  },
  mutations: {
    [RECEIVE_COLLABORATORS](
      store: collaboratorState,
      collaborators: Collaborator[],
    ) {
      store.collaborators = collaborators;
    },
    [SET_COLLABORATOR](store: collaboratorState, collaborator: Collaborator) {
      // store.collaborators.push(collaborator);
      store.collaborators.unshift(collaborator);
    },
    [UPDATE_COLLABORATOR](
      store: collaboratorState,
      collaborator: Collaborator,
    ) {
      const index = store.collaborators.findIndex(
        (pro) => pro.id == collaborator.id,
      );
      store.collaborators.splice(index, 1, collaborator);
    },
    [DELETE_COLLABORATOR](store: collaboratorState, id: number | string) {
      const index = store.collaborators.findIndex((pro) => pro.id == id);
      // console.log(id, index, store.collaborators);

      // return;
      store.collaborators.splice(index, 1);
    },
  },
  actions: {
    [FETCH_COLLABORATORS]: async (context: Context) => {
      const collaborators = await getCollaborators();
      // console.log(collaborators);

      context.commit(RECEIVE_COLLABORATORS, collaborators);
    },
  },
  getters: {
    [GET_COLLABORATORS](state: collaboratorState) {
      return state.collaborators;
    },
  },
};

export default collaboratorModule;
