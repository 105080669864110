const routes = [
  {
    path: '',
    name: 'admin',
    meta: {
      hide: true,
      requiresAuth: true,
    },
    component: () => import('@/modules/admin/layout/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'layout',
        // component: () =>
        //   import('@/modules/admin/modules/home/views/HomeView.vue'),
        children: [
          {
            path: 'home',
            name: 'home',
            component: () =>
              import('@/modules/admin/modules/home/views/HomeView.vue'),
          },
          {
            path: 'delivery',
            name: 'delivery',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/delivery/views/DeliveryView.vue'
              ),
          },
          {
            path: 'delivery/payment/:id',
            name: 'delivery.payment',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/delivery/modules/payment/views/DeliveryPayment.vue'
              ),
          },
          {
            path: 'delivery/booking/:id',
            name: 'delivery.booking',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/delivery/modules/booking/views/DeliveryBooking.vue'
              ),
          },

          {
            path: 'payments',
            name: 'payments',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/payments/views/PaymentView.vue'
              ),
          },
          {
            path: 'bookings',
            name: 'bookings',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/bookings/views/BookingsView.vue'
              ),
          },

          {
            path: 'statistics',
            name: 'statistics',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/statistics/views/StatView.vue'
              ),
          },
          {
            path: 'statistics/detailed-stock',
            name: 'statistics.details.stock',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/statistics/components/ProductStockDetailsChartComponent.vue'
              ),
          },
          {
            path: 'statistics/detailed-transactions',
            name: 'statistics.details.transactions',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/statistics/components/TransactionsDetailsChartComponent.vue'
              ),
          },
          {
            path: 'statistics/detailed-clients',
            name: 'statistics.details.clients',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/statistics/components/ClientsDetailsChartComponent.vue'
              ),
          },
          {
            path: 'chat',
            name: 'chat',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/chat/layout/ChatLayout.vue'
              ),
            children: [
              {
                path: 'room/:id',
                name: 'chatroom',
                component: () =>
                  import(
                    '@/modules/admin/modules/home/modules/chat/views/ChatRoom.vue'
                  ),
              },
            ],
            // component: () =>
            //   import(
            //     '@/modules/admin/modules/home/modules/chat/views/ChatView.vue'
            //   ),
          },
          {
            path: 'summary',
            name: 'summary',
            component: () =>
              import(
                '@/modules/admin/modules/home/modules/summary/views/SummaryView.vue'
              ),
          },
        ],
      },
      {
        path: 'products',
        name: 'products',
        meta: {
          hide: true,
          requiresAuth: true,
        },
        component: () =>
          import('@/modules/admin/modules/product/views/ProductView.vue'),
      },
      {
        path: 'transactions',
        name: 'transactions',
        meta: {
          hide: true,
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/modules/admin/modules/transaction/views/TransactionView.vue'
          ),
      },
      {
        path: 'farmacias',
        name: 'farmacias',
        meta: {
          hide: true,
          requiresAuth: true,
        },
        component: () =>
          import('@/modules/admin/modules/branch/views/BranchView.vue'),
      },
      {
        path: 'colaborators',
        name: 'colaborators',
        meta: {
          hide: true,
          requiresAuth: true,
        },
        component: () =>
          import(
            '@/modules/admin/modules/colaborator/views/ColaboratorView.vue'
          ),
      },
      {
        path: 'activities',
        name: 'activities',
        meta: {
          hide: true,
          requiresAuth: true,
        },
        component: () =>
          import('@/modules/admin/modules/activity/views/ActivityView.vue'),
      },
      {
        path: 'delivery',
        name: 'delivery',
        meta: {
          hide: true,
          requiresAuth: true,
        },
        component: () =>
          import('@/modules/admin/modules/delivery/views/DeliveryView.vue'),
      },
    ],
  },
  {
    path: 'verify-email',
    name: 'verify.email',
    component: () => import('@/modules/admin/views/verify/VerifyEmail.vue'),
    meta: {
      hide: true,
      requiresAuth: true,
    },
  },
];

export default routes;
