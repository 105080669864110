import { IPayment } from './../../models/ipayment';
import { IBooking } from './../../models/ibooking';
import { Delivery } from './../../models/delivery';
import {
  RECEIVE_DELIVERIES,
  SET_DELIVERY_BOOKING,
  SET_DELIVERY_PAYMENT,
  UPDATE_DELIVERY_BOOKING,
  UPDATE_DELIVERY_PAYMENT,
  DELETE_DELIVERY_BOOKING,
  DELETE_DELIVERY_PAYMENT,
  FETCH_DELIVERIES,
  GET_DELIVERIES,
} from './constants';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { deliveryState } from './types';
import getDeliveries from '@/api/home/delivery/getDeliveries';
import { Status } from '@/models/status';

interface Context {
  commit: Commit;
}

const deliveryModule: Module<deliveryState, State> = {
  state: {
    deliveries: {
      payments: {
        state: new Status(),
        payments: [],
      },
      bookings: {
        state: new Status(),
        bookings: [],
      },
    },
  },
  mutations: {
    [RECEIVE_DELIVERIES](store: deliveryState, deliveries: Delivery) {
      store.deliveries = deliveries;
    },

    [SET_DELIVERY_BOOKING](store: deliveryState, delivery: IBooking) {
      store.deliveries.bookings.bookings.unshift(delivery);
    },

    [UPDATE_DELIVERY_BOOKING](store: deliveryState, delivery: IBooking) {
      const index = store.deliveries.bookings.bookings.findIndex(
        (pro) => pro.id == delivery.id,
      );
      if (index !== -1) {
        // Update the payment data in your local array with the data from the API response
        if (delivery.canceled) {
          //if the payment is cancelled then we put it to last position else we keep the position
          store.deliveries.bookings.bookings.splice(index, 1)[0];
          store.deliveries.bookings.bookings.push(delivery);
          store.deliveries.bookings.state.count =
            store.deliveries.bookings.state.count - 1;
        } else {
          store.deliveries.bookings.bookings[index] = delivery;
        }
      } else {
        console.log('Product not found in the local array.');
      }
    },

    [DELETE_DELIVERY_BOOKING](store: deliveryState, id: number | string) {
      const index = store.deliveries.bookings.bookings.findIndex(
        (pro) => pro.id == id,
      );
      store.deliveries.bookings.bookings.splice(index, 1)[0];
    },

    [SET_DELIVERY_PAYMENT](store: deliveryState, delivery: IPayment) {
      store.deliveries.payments.payments.unshift(delivery);
    },

    [UPDATE_DELIVERY_PAYMENT](store: deliveryState, delivery: IPayment) {
      const index = store.deliveries.payments.payments.findIndex(
        (pro) => pro.id == delivery.id,
      );

      if (index !== -1) {
        // Update the payment data in your local array with the data from the API response
        if (delivery.canceled) {
          //if the payment is cancelled then we put it to last position else we keep the position
          store.deliveries.payments.payments.splice(index, 1)[0];
          store.deliveries.payments.payments.push(delivery);
          store.deliveries.payments.state.count =
            store.deliveries.payments.state.count - 1;
        } else {
          store.deliveries.payments.payments[index] = delivery;
        }
      } else {
        console.log('Product not found in the local array.');
      }
    },

    [DELETE_DELIVERY_PAYMENT](store: deliveryState, id: number | string) {
      const index = store.deliveries.payments.payments.findIndex(
        (pro) => pro.id == id,
      );
      store.deliveries.payments.payments.splice(index, 1)[0];
    },
  },
  actions: {
    [FETCH_DELIVERIES]: async (context: Context) => {
      const deliveries = await getDeliveries();
      // console.log(deliveries);

      context.commit(RECEIVE_DELIVERIES, deliveries);
    },
  },
  getters: {
    [GET_DELIVERIES](state: deliveryState) {
      return state.deliveries;
    },
  },
};

export default deliveryModule;
