import { store } from '@/store';
import api from '..';
import { AUTH_TOKEN } from '@/constants';
import { User } from '@/models/user';

const getLoggedUser = async () => {
  try {
    // console.log('chek tken api', localStorage.getItem(AUTH_TOKEN));
    const baseUrl = process.env.VUE_APP_API_URL;
    const response = await api.get<any>(`${baseUrl}/me`);
    // console.log('getter api', response);

    if (response.data.status === 'failure') {
      return response.data.user;
    } else {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    store.commit('LOGOUT_USER');
    localStorage.removeItem(AUTH_TOKEN);
  }
};

export default getLoggedUser;
