import { Category } from '@/models/category';
import api from '..';

const getCategories = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Category>(`${baseUrl}/categories`);
  // console.log('category', response);

  return response.data;
};

export default getCategories;
