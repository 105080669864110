import { IPayment } from '@/models/ipayment';
import { Payment } from '@/models/payment';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { paymentState } from './types';
import {
  FETCH_PAYMENTS,
  RECEIVE_PAYMENTS,
  GET_PAYMENTS,
  SET_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
} from './constants';
import getPayments from '@/api/home/payment/getPayments';
import { Status } from '@/models/status';

interface Context {
  commit: Commit;
}

const paymentModule: Module<paymentState, State> = {
  state: {
    payments: {
      state: new Status(),
      payments: [],
    },
  },
  mutations: {
    [RECEIVE_PAYMENTS](store: paymentState, payments: Payment) {
      store.payments = payments;
    },
    [SET_PAYMENT](store: paymentState, payment: IPayment) {
      store.payments.payments.unshift(payment);
    },
    [UPDATE_PAYMENT](store: paymentState, payment: IPayment) {
      const index = store.payments.payments.findIndex(
        (pro) => pro.id == payment.id,
      );
      // Check if the payment with the given ID is found
      if (index !== -1) {
        // Update the payment data in your local array with the data from the API response
        if (payment.canceled) {
          //if the payment is cancelled then we put it to last position else we keep the position
          store.payments.payments.splice(index, 1)[0];
          store.payments.payments.push(payment);
          store.payments.state.count = store.payments.state.count - 1;
        } else {
          store.payments.payments[index] = payment;
        }
      } else {
        console.log('Product not found in the local array.');
      }
    },
    [DELETE_PAYMENT](store: paymentState, id: number | string) {
      const index = store.payments.payments.findIndex((pro) => pro.id == id);
      store.payments.payments.splice(index, 1)[0];
    },
  },
  actions: {
    [FETCH_PAYMENTS]: async (context: Context) => {
      const payments = await getPayments();
      // console.log(payments);

      context.commit(RECEIVE_PAYMENTS, payments);
    },
  },
  getters: {
    [GET_PAYMENTS](state: paymentState) {
      return state.payments;
    },
  },
};

export default paymentModule;
