import { Product } from '@/models/product';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { productState } from './types';
import {
  FETCH_PRODUCTS,
  RECEIVE_PRODUCTS,
  GET_PRODUCTS,
  SET_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from './constants';
import getProducts from '@/api/product/getProducts';

interface Context {
  commit: Commit;
}

const productModule: Module<productState, State> = {
  state: {
    products: [],
  },
  mutations: {
    [RECEIVE_PRODUCTS](store: productState, products: Product[]) {
      store.products = products;
    },
    [SET_PRODUCT](store: productState, product: Product) {
      store.products.unshift(product);
    },
    [UPDATE_PRODUCT](store: productState, products: Product[]) {
      products.forEach((prod) => {
        const index = store.products.findIndex(
          (product) => product.id === prod.id,
        );

        if (index !== -1) {
          // Update the existing product with the updated product
          store.products[index] = prod;
        } else {
          // Handle the case where the product is not found
          console.error(`Product with ID ${prod.id} not found in the store.`);
        }
      });
    },
    // [UPDATE_PRODUCT](store: productState, product: Product[]) {
    //   const index = store.products.findIndex((pro) => pro.id == product.id);
    //   store.products.splice(index, 1, product);
    // },
    [DELETE_PRODUCT](store: productState, id: number | string) {
      const index = store.products.findIndex((pro) => pro.id == id);
      store.products.splice(index, 1);
    },
  },
  actions: {
    [FETCH_PRODUCTS]: async (context: Context) => {
      const products = await getProducts();
      // console.log(products);

      context.commit(RECEIVE_PRODUCTS, products);
    },
  },
  getters: {
    [GET_PRODUCTS](state: productState) {
      return state.products;
    },
  },
};

export default productModule;
