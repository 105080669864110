import api from '@/api';
import { Delivery } from '@/models/delivery';

const getDeliveries = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Delivery>(`${baseUrl}/delivery`);
  // console.log('product', response.data);

  return response.data;
};

export default getDeliveries;
