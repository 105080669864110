import { IBooking } from '@/models/ibooking';
import { Booking } from '@/models/booking';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { bookingState } from './types';
import {
  FETCH_BOOKINGS,
  RECEIVE_BOOKINGS,
  GET_BOOKINGS,
  SET_BOOKING,
  UPDATE_BOOKING,
  DELETE_BOOKING,
} from './constants';
import getBookings from '@/api/home/booking/getBookings';
import { Status } from '@/models/status';

interface Context {
  commit: Commit;
}

const bookingModule: Module<bookingState, State> = {
  state: {
    bookings: {
      state: new Status(),
      bookings: [],
    },
  },
  mutations: {
    [RECEIVE_BOOKINGS](store: bookingState, bookings: Booking) {
      store.bookings = bookings;
    },
    [SET_BOOKING](store: bookingState, booking: IBooking) {
      store.bookings.bookings.unshift(booking);
    },
    [UPDATE_BOOKING](store: bookingState, booking: IBooking) {
      const index = store.bookings.bookings.findIndex(
        (pro) => pro.id == booking.id,
      );

      // Check if the payment with the given ID is found
      if (index !== -1) {
        // Update the payment data in your local array with the data from the API response
        if (booking.canceled) {
          //if the payment is cancelled then we put it to last position else we keep the position
          store.bookings.bookings.splice(index, 1)[0];
          store.bookings.bookings.push(booking);
          store.bookings.state.count = store.bookings.state.count - 1;
        } else {
          store.bookings.bookings[index] = booking;
        }
      } else {
        console.log('Product not found in the local array.');
      }
    },
    [DELETE_BOOKING](store: bookingState, id: number | string) {
      const index = store.bookings.bookings.findIndex((pro) => pro.id == id);
      store.bookings.bookings.splice(index, 1)[0];
    },
  },
  actions: {
    [FETCH_BOOKINGS]: async (context: Context) => {
      const bookings = await getBookings();
      // console.log(bookings);

      context.commit(RECEIVE_BOOKINGS, bookings);
    },
  },
  getters: {
    [GET_BOOKINGS](state: bookingState) {
      return state.bookings;
    },
  },
};

export default bookingModule;
