export const RECEIVE_ROOMS = 'RECEIVE_ROOMS';
export const RECEIVE_ROOM_PRODUCTS = 'RECEIVE_ROOM_PRODUCTS';
export const GET_ROOMS = 'GET_ROOMS';
export const GET_ROOM_PRODUCTS = 'GET_ROOM_PRODUCTS';
export const SET_ROOM = 'SET_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';

export const RECEIVE_ROOT = 'RECEIVE_ROOT';
export const GET_ROOT = 'GET_ROOT';

export const FETCH_ROOMS = 'FETCH_ROOMS';
