import api from '..';
import { Room } from '@/models/room';

const startChat = async (id: number) => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.post<Room>(`${baseUrl}/room`, {
    client_id: id,
  });
  // console.log('chat', response.data);

  return response.data;
};

export default startChat;
