export default {
  login: {
    slogan: 'Saúde para todos',
  },
  admin: {
    components: {
      error: {
        title: 'Error message',
      },
      confirm: {
        confirmTitle: 'Pretende confirmar a venda?',
        cancelTitle: 'Pretende cancelar a venda?',
        confirmBtn: 'Sim',
        cancelBtn: 'Não',
      },
      success: {
        title: 'success mesage',
      },
    },
    layout: {
      nav: {
        enBtn: 'Inglês',
        ptBtn: 'Português',
      },
    },
    home: {
      delivery: {
        title1: 'Compras com entrega',
        title2: 'Reservas com entrega',
        options: {
          payment: 'Compras',
          booking: 'Reservas',
        },
        table: {
          client: 'Cliente',
          quantity: 'Quantidade',
          paidPrice: 'Vt. pago',
          date: 'D. compra',
          contact: 'Contacto',
        },
        actions: {
          startChat: 'Iniciar conversa',
          requestDelivery: 'Solicitar entrega',
          confirmDeliery: 'Confirmar entrega',
          cancelSale: 'Cancelar venda ',
          confimReturn: 'Confirmar devolução',
          moreDetails: 'Mais detalhes',
        },
        success: {
          confirm: 'Venda efectuada com sucesso!',
          cancel: 'Venda cancelada com sucesso!',
        },
      },

      payment: {
        title: 'Compras',
        status: {
          normal: 'Normal',
          medium: 'Médio',
          low: 'Baixo',
        },
      },
      booking: {
        title: 'Reservas',
        status: {
          normal: 'Normal',
          medium: 'Médio',
          low: 'Baixo',
        },
      },
      stock: {
        title: 'Estado do stock',
        high: 'Stock alto',
        medium: 'Stock médio',
        low: 'Stock baixo',
      },
      chat: {
        title: 'Mensagens',
        table: {
          name: 'Nome',
          message: 'Mensagem',
          time: 'Tempo',
        },
      },
      summary: {
        title: 'Resumo das vendas efectuadas',
        table: {
          image: '',
          product: 'Produto',
          soldQtn: 'Q. vendida',
          payment: 'Potal pago (100%)',
          booking: 'Total pago (50%)',
          available: 'Valor disponível',
          stock: 'Stock disponível',
          actions: 'Acções',
          button: 'Actualizar stock',
        },
        labels: {
          excluded: 'Excluído',
        },
      },
    },
    delivery: {
      title1: 'Compras/entregas pendentes',
      title2: 'Reservas/entregas pendentes',
      options: {
        payment: 'Compras',
        booking: 'Reservas',
      },
      table: {
        image: '',
        client: 'Cliente',
        products: 'Nr. produtos',
        quantity: 'Quantidade',
        PaidPrice: 'Vt. pago',
        date: 'D. compra',
        contact: 'Contacto',
        actions: 'Acções',
      },
      innerTable: {
        image: '',
        product: 'Produto',
        quantity: 'Quantidade',
        price: 'Preço ',
        paidPrice: 'V. pago',
      },
      actions: {
        startChat: 'Iniciar conversa',
        requestDelivery: 'Solicitar entrega',
        confirmDeliery: 'Confirmar entrega',
        cancelSale: 'Cancelar venda ',
        confimReturn: 'Confirmar devolução',
        moreDetails: 'Mais detalhes',
      },
      deliveryDetails: {
        title: 'Mais Detalhes',
        table: {
          client: 'Nome do Cliente',
          product: 'Produto',
          paid_date: 'D. compra',
          province: 'Província de entrega',
          address: 'End. de Entrega',
          receptorContact: 'Cont. do receptor',
          agent: 'Ag. entrega',
          agentContact: 'Cont. Ag. entrega',
          deliveryDate: 'D. entrega',
        },
        actions: {
          startChat: 'Iniciar conversa',
          requestDelivery: 'Solicitar entrega',
          confirmDeliery: 'Confirmar entrega',
          cancelSale: 'Cancelar venda ',
          confimReturn: 'Confirmar devolução',
          moreDetails: 'Mais detalhes',
        },
        statuses: {
          canceled: 'Cancelado',
          completed: 'Concluído',
        },
      },
      status: {
        normal: 'Normal',
        medium: 'Médio',
        low: 'Baixo',
      },
      reason: {
        title: 'Motivo do cancelamento.',
      },
      success: {
        confirm: 'Venda efectuada com sucesso!',
        cancel: 'Venda cancelada com sucesso!',
      },
    },
    payments: {
      title: 'Compras',
      table: {
        image: '',
        client: 'Cliente',
        products: 'Nr. produtos',
        quantity: 'Quantidade',
        PaidPrice: 'Vt. pago',
        date: 'D. compra',
        contact: 'Contacto',
        actions: 'Acções',
      },
      innerTable: {
        image: '',
        product: 'Produto',
        quantity: 'Quantidade',
        price: 'Preço ',
        paidPrice: 'V. pago',
      },
      status: {
        normal: 'Normal',
        medium: 'Médio',
        low: 'Baixo',
      },
      confirm: {
        title: 'Pretende confirmar a venda?',
      },
      cancel: {
        title: 'Pretende cancelar a venda?',
        reasonTitle: 'Indique o motivo no campo abaixo.',
        reasonTitle2:
          'O motivo do cancelamento será enviado por email para o cliente e o valor pago pelo produto será reembolsado.',
        placeholder: 'Especifique o motivo nesta área',
        confirmBtn: 'Confirmar',
        cancelBtn: 'Cancelar',
      },
      reason: {
        title: 'Motivo do cancelamento.',
      },
      success: {
        confirm: 'Venda efectuada com sucesso!',
        cancel: 'Venda cancelada com sucesso!',
      },
      labels: {
        canceled: 'Cancelado',
        complete: 'Concluído',
        removed: 'Excluído',
      },
    },
    bookings: {
      title: 'Reservas',
      table: {
        image: '',
        client: 'Cliente',
        products: 'Nr. produtos',
        quantity: 'Quantidade',
        PaidPrice: 'Vt. pago',
        date: 'D. compra',
        contact: 'Contacto',
        actions: 'Acções',
      },
      innerTable: {
        image: '',
        product: 'Produto',
        quantity: 'Quantidade',
        price: 'Preço ',
        paidPrice: 'V. pago',
        actions: '',
      },
      status: {
        normal: 'Normal',
        medium: 'Médio',
        low: 'Baixo',
      },
      confirm: {
        title: 'Pretende confirmar a venda?',
      },
      cancel: {
        title: 'Pretende cancelar a venda?',
        reaspnTitle: 'Indique o motivo no campo abaixo.',
        reasonTitle2:
          'O motivo do cancelamento será enviado por email para o cliente e o valor pago pelo produto será reembolsado.',
        placeholder: 'Especifique o motivo nesta área',
        confirmBtn: 'Confirmar',
        cancelBtn: 'Cancelar',
      },
      reason: {
        title: 'Motivo do cancelamento.',
      },
      success: {
        confirm: 'Venda efectuada com sucesso!',
        cancel: 'Venda cancelada com sucesso!',
      },
      labels: {
        canceled: 'Cancelado',
        complete: 'Concluído',
        removed: 'Excluído',
      },
    },
    statistics: {
      stock: { title: 'Estado do stock' },
      products: {
        title: 'Stock detalhado',
        filter: {
          all: 'Todos',
          unit: 'Unidade',
          pack: 'Embalagem',
          wallet: 'Carteira',
        },
        yAxis: 'Quantidade disponível',
      },
      transactions: {
        title: 'Transações',
        title2: 'Transações Detalhado',
        filter: {
          day: 'Dia',
          week: 'Semana',
          month: 'Mês',
          year: 'Ano',
        },
        yAxis: 'Valor disponível (MZN)',
      },
      clients: {
        title: 'Clientes & Visitantes',
        active: 'Clientes activos',
        visitors: 'Visitantes',
        filter: {
          day: 'Dia',
          week: 'Semana',
          month: 'Mês',
          year: 'Ano',
        },
      },
    },
    chat: {
      tabs: {
        patient: 'Paciente',
        support: 'Suporte',
      },
      title: 'Mensagens',
      messagePlaceholder: 'Escreva a sua mensagem aqui',
      menu: {
        photo: 'Anexar foto',
        product: 'Adicionar produto',
      },
      productSelect: 'Seleccionar Produto',
    },
  },
};
