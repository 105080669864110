import api from '..';
import { Permission } from '@/models/permission';

const getPermissions = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Permission>(`${baseUrl}/permissions`);
  // console.log('category', response);

  return response.data;
};

export default getPermissions;
