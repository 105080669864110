import { Role } from '@/models/role';
import { roleState } from './types';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { FETCH_ROLES, RECEIVE_ROLES, GET_ROLES, SET_ROLE } from './constants';
import getRoles from '@/api/role/getRole';

interface Context {
  commit: Commit;
}

const roleModule: Module<roleState, State> = {
  state: {
    roles: [],
  },
  mutations: {
    [RECEIVE_ROLES](store: roleState, roles: Role[]) {
      store.roles = roles;
    },
    [SET_ROLE](store: roleState, role: Role) {
      store.roles.push(role);
    },
  },
  actions: {
    [FETCH_ROLES]: async (context: Context) => {
      const roles = await getRoles();
      // console.log(roles);

      context.commit(RECEIVE_ROLES, roles);
    },
  },
  getters: {
    [GET_ROLES](state: roleState) {
      return state.roles;
    },
  },
};

export default roleModule;
