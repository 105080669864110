import { Collaborator } from '@/models/collaborator';
import api from '..';

const getCollaborators = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Collaborator>(`${baseUrl}/collaborators`);
  // console.log('product', response.data);

  return response.data;
};

export default getCollaborators;
