import { Permission } from './../../models/permission';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { permissionState } from './types';
import {
  FETCH_PERMISSIONS,
  RECEIVE_PERMISSIONS,
  GET_PERMISSIONS,
  SET_PERMISSION,
} from './constants';
import getPermissions from '@/api/permission/getPermissions';

interface Context {
  commit: Commit;
}

const permissionModule: Module<permissionState, State> = {
  state: {
    permissions: [],
  },
  mutations: {
    [RECEIVE_PERMISSIONS](store: permissionState, permissions: Permission[]) {
      store.permissions = permissions;
    },
    [SET_PERMISSION](store: permissionState, cat: Permission) {
      store.permissions.push(cat);
    },
  },
  actions: {
    [FETCH_PERMISSIONS]: async (context: Context) => {
      const permissions = await getPermissions();
      // console.log(permissions);

      context.commit(RECEIVE_PERMISSIONS, permissions);
    },
  },
  getters: {
    [GET_PERMISSIONS](state: permissionState) {
      return state.permissions;
    },
  },
};

export default permissionModule;
