export const RECEIVE_CHATS = 'RECEIVE_CHATS';
export const GET_CHATS = 'GET_CHATS';
export const SET_CHAT = 'SET_CHAT';
export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const FETCH_ACTIVE_CHAT = 'FETCH_ACTIVE_CHAT';
export const GET_ACTIVE_CHAT = 'GET_ACTIVE_CHAT';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const DELETE_CHAT = 'DELETE_CHAT';

export const FETCH_CHATS = 'FETCH_CHATS';
