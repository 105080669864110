import { UPDATE_BRANCH, DELETE_BRANCH } from './../home/constants';
import { Branch } from '@/models/branch';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { branchState } from './types';
import {
  FETCH_BRANCHES,
  RECEIVE_BRANCHES,
  GET_BRANCHES,
  SET_BRANCH,
} from './constants';
import getBranches from '@/api/branch/getBranches';

interface Context {
  commit: Commit;
}

const branchModule: Module<branchState, State> = {
  state: {
    branches: [],
  },
  mutations: {
    [RECEIVE_BRANCHES](store: branchState, branches: Branch[]) {
      store.branches = branches;
    },
    [SET_BRANCH](store: branchState, branch: Branch) {
      store.branches.unshift(branch);
    },
    [UPDATE_BRANCH](store: branchState, branch: Branch) {
      const index = store.branches.findIndex((pro) => pro.id == branch.id);
      store.branches.splice(index, 1, branch);
    },
    [DELETE_BRANCH](store: branchState, id: number | string) {
      const index = store.branches.findIndex((pro) => pro.id == id);
      store.branches.splice(index, 1);
    },
  },
  actions: {
    [FETCH_BRANCHES]: async (context: Context) => {
      const branches = await getBranches();
      // console.log(branches);

      context.commit(RECEIVE_BRANCHES, branches);
    },
  },
  getters: {
    [GET_BRANCHES](state: branchState) {
      return state.branches;
    },
  },
};

export default branchModule;
