import { Province } from '@/models/province';
import axios from 'axios';

const getProvinces = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await axios.get<Province>(`${baseUrl}/provinces`);

  // console.log(response.data);
  return response.data;
};

export default getProvinces;
