// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { VDataTable } from 'vuetify/labs/VDataTable';
import { VOtpInput } from 'vuetify/labs/VOtpInput';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';

// Vuetify
import { createVuetify, ThemeDefinition } from 'vuetify';
import * as directives from 'vuetify/directives';

const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#3D9C3E',
    'primary-darken-1': '#287529',
    secondary: '#A6C87B',
    'secondary-lighten-1': '#C0D4A6',
    muted: '#E2EDD4',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

export default createVuetify({
  components: {
    VDataTable,
    VOtpInput,
    VSkeletonLoader,
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  directives,
});

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
