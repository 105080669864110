import { Module } from 'vuex';
import { State } from '..';
import { RegisterState } from './types';

// Update the path to your fakeApi

const registerModule: Module<RegisterState, State> = {
  state: {
    data1: {
      name: '',
      email: '',
      surname: '',
      password: '',
    },
    data2: {
      company: '',
      website: '',
      social: '',
      address: '',
    },
    data3: {
      bank: '',
      balance: '',
      country: '',
    },
  },
  mutations: {
    setData1(state, data) {
      state.data1 = data;
    },
    setData2(state, data) {
      state.data2 = data;
    },
    setData3(state, data) {
      state.data3 = data;
    },
    clearData(state) {
      state.data1 = { name: '', email: '', surname: '', password: '' };
      state.data2 = { company: '', website: '', social: '', address: '' };
      state.data3 = { bank: '', balance: '', country: '' };
    },
  },
  actions: {
    // Actions for setting and clearing data
    setAndClearData({ commit }, { data1, data2, data3 }) {
      commit('setData1', data1);
      commit('setData2', data2);
      commit('setData3', data3);
    },
    clearAllData({ commit }) {
      commit('clearData');
    },
  },
  getters: {
    // Getters for accessing data
    getData1: (state) => state.data1,
    getData2: (state) => state.data2,
    getData3: (state) => state.data3,
  },
};

export default registerModule;
