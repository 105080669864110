import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { AUTH_TOKEN } from '@/constants';
import authRoutes from '@/modules/auth/router';
import adminRoutes from '@/modules/admin/router';
import { store } from '@/store';
// import { h } from 'vue';
import i18n from '@/i18n';
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'base',
  //   redirect: { name: 'login' },
  // },
  {
    path: '/:lang',
    name: 'locale',
    // component: {
    //   render() {
    //     return h('router-view');
    //   },
    // },
    children: [...adminRoutes, ...authRoutes],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // console.log();
  // If no language param is set, redirect to the default language route
  if (!to.params.lang) {
    const defaultLanguage = process.env.VUE_APP_DEFAULT_LOCALE || 'pt';
    return next({ name: 'locale', params: { lang: defaultLanguage } });
  }

  // set the language in i18n to current param lang
  i18n.global.locale.value = to.params.lang;

  //update the url tab title
  if (typeof to.meta?.title === 'string') {
    document.title = to.meta.title;
  } else {
    document.title = 'QININA APP';
  }

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      router.push({ name: 'login', params: { lang: to.params.lang } });
    }
    //still need to do some modifications here
    const token = window.localStorage.getItem(AUTH_TOKEN);
    const loginRoute = {
      path: `/${to.params.lang}/login`,
      query: { redirect: to.fullPath },
    };
    if (token) {
      try {
        // await getLoggedUser();
        return next();
      } catch (error) {
        const noInternet = window.navigator.onLine ? false : true;
        if (noInternet) {
          alert('No Internet Connection');
          return next(false);
        } else {
          return next(loginRoute);
        }
      }
    }
    return next(loginRoute);
  }
  next();
});

export default router;
