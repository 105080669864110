import {
  RECEIVE_CHATS,
  SET_CHAT,
  UPDATE_CHAT,
  DELETE_CHAT,
  FETCH_CHATS,
  GET_CHATS,
  SET_ACTIVE_CHAT,
  FETCH_ACTIVE_CHAT,
  GET_ACTIVE_CHAT,
} from './constants';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { chatState } from './types';
import { ClientMessages } from '@/models/clientMessages';
import startChat from '@/api/chat/startChat';
import getChatUsers from '@/api/chat/getChatMessages';
import getChats from '@/api/chat/getChats';

interface Context {
  commit: Commit;
}

const chatModule: Module<chatState, State> = {
  state: {
    chatList: [],
    activeChat: new ClientMessages(),
  },
  mutations: {
    [RECEIVE_CHATS](store: chatState, chats: ClientMessages[]) {
      store.chatList = chats;
    },

    [SET_CHAT](store: chatState, chat: ClientMessages) {
      store.chatList.push(chat);
    },

    [SET_ACTIVE_CHAT](store: chatState, chat: ClientMessages) {
      store.activeChat = chat;
    },

    [UPDATE_CHAT](store: chatState, chat: ClientMessages) {
      const index = store.chatList.findIndex(
        (pro) => pro.cli_id == chat.cli_id,
      );

      store.chatList.splice(index, 1, chat);
    },

    [DELETE_CHAT](store: chatState, id: number | string) {
      const index = store.chatList.findIndex((pro) => pro.cli_id == id);
      store.chatList.splice(index, 1)[0];
    },
  },
  actions: {
    [FETCH_CHATS]: async (context: Context) => {
      const chats = await getChats();
      // console.log(chats);
      context.commit(RECEIVE_CHATS, chats);
    },
    [FETCH_ACTIVE_CHAT]: async (context: Context, id: number) => {
      const chat = await startChat(id);
      // console.log(chat);
      context.commit(SET_ACTIVE_CHAT, chat);
    },
  },
  getters: {
    [GET_CHATS](state: chatState) {
      return state.chatList;
    },
    [GET_ACTIVE_CHAT](state: chatState) {
      return state.activeChat;
    },
  },
};

export default chatModule;
