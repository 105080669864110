export default {
  login: {
    slogan: 'Health for everyone',
  },
  admin: {
    components: {
      error: {
        title: 'error message',
      },
      confirm: {
        confirmTitle: 'Do you want to confirm the sale?',
        cancelTitle: 'Do you want to cancel the sale?',
        confirmBtn: 'Yes',
        cancelBtn: 'No',
      },
      success: {
        title: 'Success message',
      },
    },
    layout: {
      nav: {
        enBtn: 'English',
        ptBtn: 'Portuguese',
      },
    },
    home: {
      delivery: {
        title1: 'Purchases with delivery',
        title2: 'Reservations with delivery',
        options: {
          payment: 'Purchases',
          booking: 'Reservations',
        },
        table: {
          client: 'Client',
          quantity: 'Quantity',
          paidPrice: 'T. A paid',
          date: 'Purchase date',
          contact: 'Contact',
        },
        actions: {
          startChat: 'Start conversation',
          requestDelivery: 'Request delivery',
          confirmDeliery: 'Confirm delivery',
          cancelSale: 'Cancel sale',
          confimReturn: 'Confirm return',
          moreDetails: 'More details',
        },
        success: {
          confirm: 'Sale successfully completed!',
          cancel: 'Sale successfully cancelled!',
        },
      },
      payment: {
        title: 'Purchases',
        status: {
          normal: 'Normal',
          medium: 'Medium',
          low: 'Low',
        },
      },
      booking: {
        title: 'Reservations',
        status: {
          normal: 'Normal',
          medium: 'Medium',
          low: 'Low',
        },
      },
      stock: {
        title: 'Stock status',
        high: 'High stock',
        medium: 'Medium stock',
        low: 'Low stock',
      },
      chat: {
        title: 'Messages',
        table: {
          name: 'Name',
          message: 'Message',
          time: 'Time',
        },
      },
      summary: {
        title: 'Summary of sales made',
        table: {
          image: '',
          product: 'Product',
          soldQtn: 'Q. sold',
          payment: 'Total paid (100%)',
          booking: 'Total paid (50%)',
          available: 'Amount available',
          stock: 'Available stock',
          actions: 'Actions',
          button: 'Update stock',
        },
        labels: {
          excluded: 'Excluded',
        },
      },
    },
    delivery: {
      title1: 'Pending purchases/deliveries',
      title2: 'Pending reservations/deliveries',
      options: {
        payment: 'Purchases',
        booking: 'Reservations',
      },
      table: {
        image: '',
        client: 'client',
        products: 'N. of products',
        quantity: 'Quantity',
        PaidPrice: 'T. A paid',
        date: 'Purchase date',
        contact: 'Contact',
        actions: 'Actions',
      },
      innerTable: {
        image: '',
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price',
        paidPrice: 'A. Paid',
      },
      actions: {
        StartChat: 'Start conversation',
        requestDelivery: 'Request delivery',
        confirmDeliery: 'Confirm delivery',
        cancelSale: 'Cancel sale',
        confimReturn: 'Confirm return',
        moreDetails: 'More details',
      },
      deliveryDetails: {
        title: 'More Details',
        table: {
          client: 'Client Name',
          product: 'Produt',
          paid_date: 'Sale Date',
          province: 'Delivery Province',
          address: 'Delivery Address',
          receptorContact: 'Receptor Contact',
          agent: 'Delivery Agent',
          agentContact: 'Delivery Agent Cont.',
          deliveryDate: 'Delivery Date',
        },
        actions: {
          startChat: 'Start conversation',
          requestDelivery: 'Request delivery',
          confirmDeliery: 'Confirm delivery',
          cancelSale: 'Cancel sale',
          confimReturn: 'Confirm return',
          moreDetails: 'More details',
        },
        statuses: {
          canceled: 'Cancelled',
          completed: 'Completed',
        },
      },
      status: {
        normal: 'Normal',
        medium: 'Medium',
        low: 'Low',
      },
      reason: {
        title: 'Reason for cancellation.',
      },
      success: {
        confirm: 'Sale successfully completed!',
        cancel: 'Sale successfully cancelled!',
      },
    },
    payments: {
      title: 'Purchases',
      table: {
        image: '',
        client: 'client',
        products: 'N. of products',
        quantity: 'Quantity',
        PaidPrice: 'T. A paid',
        date: 'Purchase date',
        contact: 'Contact',
        actions: 'Actions',
      },
      innerTable: {
        image: '',
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price',
        paidPrice: 'A. Paid',
      },
      status: {
        normal: 'Normal',
        medium: 'Medium',
        low: 'Low',
      },
      confirm: {
        title: 'Do you want to confirm the sale?',
      },
      cancel: {
        title: 'Do you want to cancel the sale?',
        reasonTitle: 'Indicate the reason in the field below.',
        reasonTitle2:
          'The reason for cancellation will be sent by email to the customer and the amount paid for the product will be refunded',
        placeholder: 'Specify the reason in this field.',
        confirmBtn: 'Confirm',
        cancelBtn: 'Cancel',
      },
      reason: {
        title: 'Reason for cancellation.',
      },
      success: {
        confirm: 'Sale successfully completed!',
        cancel: 'Sale successfully cancelled!',
      },
      labels: {
        canceled: 'Cancelled',
        complete: 'Completed',
        removed: 'Excluded',
      },
    },
    bookings: {
      title: 'Reservations',
      table: {
        image: '',
        client: 'client',
        products: 'N. of products',
        quantity: 'Quantity',
        PaidPrice: 'T. A paid',
        date: 'Purchase date',
        contact: 'Contact',
        actions: 'Actions',
      },
      innerTable: {
        image: '',
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price',
        paidPrice: 'A. Paid',
        actions: '',
      },
      status: {
        normal: 'Normal',
        medium: 'Medium',
        low: 'Low',
      },
      confirm: {
        title: 'Do you want to confirm the sale?',
      },
      cancel: {
        title: 'Do you want to cancel the sale?',
        reasonTitle: 'Indicate the reason in the field below.',
        reasonTitle2:
          'The reason for cancellation will be sent by email to the customer and the amount paid for the product will be refunded',
        placeholder: 'Specify the reason in this field.',
        confirmBtn: 'Confirm',
        cancelBtn: 'Cancel',
      },
      reason: {
        title: 'Reason for cancellation.',
      },
      success: {
        confirm: 'Sale successfully completed!',
        cancel: 'Sale successfully cancelled!',
      },
      labels: {
        canceled: 'Cancelled',
        complete: 'Completed',
        removed: 'Excluded',
      },
    },
    statistics: {
      stock: {
        title: 'Stock status',
        high: 'High stock',
        medium: 'Medium stock',
        low: 'Low stock',
      },
      products: {
        title: 'Detailed stock',
        filter: {
          all: 'all',
          unit: 'unit',
          pack: 'package',
          wallet: 'wallet',
        },
        yAxis: 'Available quantity',
      },
      transactions: {
        title: 'Transactions',
        title2: 'Detailed transactions',
        filter: {
          day: 'Day',
          week: 'Week',
          month: 'Month',
          year: 'Year',
        },
        yAxis: 'Available ammount (MZN)',
      },
      clients: {
        title: 'Clients & Visitors',
        active: 'Active clients',
        visitors: 'Visitors',
        filter: {
          day: 'Day',
          week: 'Week',
          month: 'Month',
          year: 'Year',
        },
      },
    },
    chat: {
      tabs: {
        patient: 'Patient',
        support: 'Support',
      },
      title: 'Messages',
      messagePlaceholder: 'Write your message here',
      menu: {
        photo: 'Attach photo',
        product: 'Add product',
      },
      productSelect: 'Select Product',
    },
  },
};
