import api from '..';
import { Branch } from '@/models/branch';

const getFarmacias = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Branch>(`${baseUrl}/farmacias`);
  // console.log('product', response.data);

  return response.data;
};

export default getFarmacias;
