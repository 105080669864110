// import { AUTH_TOKEN } from '@/constants';
import axios from 'axios';

const httpEndpoint =
  process.env.VUE_APP_API_URL || 'http://localhost:3000/api/admin';

// const token = localStorage.getItem(AUTH_TOKEN);
// console.log('log from api file', token);

const api = axios.create({
  baseURL: httpEndpoint, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${token}`, // Include the token in the Authorization header
  },
});

export default api;
