import api from '..';

const getRooms = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<any>(`${baseUrl}/room`);
  // console.log('product', response.data);

  return response.data;
};

export default getRooms;
