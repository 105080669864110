import { AUTH_TOKEN } from './constants/index';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store/index';

//vuetify
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

// ant design
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import './assets/styles/custom.css'; // Import your custom CSS file here
require('@/api/subscriber');

import i18n from './i18n'; // Import the i18n configuration

loadFonts();

store.dispatch('FETCH_LOGGED_USER');

// Create Vue app
// const app = createApp(App);

// // Use plugins
// app.use(i18n).use(router).use(store, key).use(vuetify).use(Antd);

// // // Provide Echo instance globally
// // app.config.globalProperties.$echo = Echo;

// // Mount the app
// app.mount('#app');

import VueChatScroll from 'vue3-chat-scroll';

createApp(App)
  .use(i18n)
  .use(router)
  .use(store, key)
  .use(vuetify)
  .use(Antd)
  .use(VueChatScroll)
  .mount('#app');
