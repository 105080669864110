import { Role } from '@/models/role';
import api from '..';

const getRoles = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Role>(`${baseUrl}/roles`);
  // console.log('category', response);

  return response.data;
};

export default getRoles;
