import api from '@/api';
import { Booking } from '@/models/booking';

const getBookings = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Booking>(`${baseUrl}/bookings`);
  // console.log('product', response.data);

  return response.data;
};

export default getBookings;
