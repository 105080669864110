import { Product } from '@/models/product';
import api from '..';

const getProducts = async () => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await api.get<Product>(`${baseUrl}/products`);
  // console.log('product', response.data);

  return response.data;
};

export default getProducts;
